import React, { useEffect, useState } from 'react';
import {
  Table, Button, Row, Col, Alert,
} from 'react-bootstrap';
import { faSortDown, faSortUp, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../components/main-layout';
import FormInput from '../../components/form-input';
import CSVUploader from '../../components/csv-upload';
import {
  tableTitles, setTableTitles, data, setData,
} from '../../utils/roster';

const AddStudents = ({ location, history }) => {
  const initialValue = [
    {
      id: 1, lastName: null, firstName: null, username: null, newRow: false,
    }];
  const [students, setStudents] = useState();
  const [tableInput, setTableInput] = useState(initialValue);
  const [sortingParams, setSortingParams] = useState({
    title: 'last_name',
    orderDescending: true,
  });
  const [error, setError] = useState();

  useEffect(() => {
    setStudents(data);
    if (location.state !== undefined && location.state) setTableTitles(location.state.selectedCourse.enrollment_type);
  }, []);

  const renderStudentTableHeader = () => tableTitles.map((item, index) => {
    const isChosen = sortingParams.title === item.value;
    let sortUpColor;
    let sortDownColor;
    if (isChosen) {
      const isDesc = sortingParams.order;
      if (isDesc) {
        sortUpColor = 'rgba(255, 255, 255, 0.4)';
        sortDownColor = 'white';
      } else {
        sortUpColor = 'white';
        sortDownColor = 'rgba(255, 255, 255, 0.4)';
      }
    } else {
      sortUpColor = 'rgba(255, 255, 255, 0.4)';
      sortDownColor = 'rgba(255, 255, 255, 0.4)';
    }
    return (
      <th key={index}>
        <Button
          className="d-flex align-items-center bg-transparent shadow-none border-0"
          size="sm"
        >
          <b>
            {item.title}
          </b>
          <div className="d-flex flex-column align-items-center ml-2">
            <FontAwesomeIcon icon={faSortUp} color={sortUpColor} />
            <FontAwesomeIcon
              icon={faSortDown}
              color={sortDownColor}
              style={{ marginTop: -12 }}
            />
          </div>
        </Button>
      </th>
    );
  });
  const onValueChange = (value, id) => {
    const input = tableInput;
    const type = id.split('_')[0];
    const row = id.split('_')[1] - 1;
    switch (type) {
      case 'lastName':
        input[row].lastName = value;
        break;
      case 'firstName':
        input[row].firstName = value;
        break;
      case 'email':
        input[row].username = value;
        break;
      case 'studentId':
        input[row].studentId = value;
        break;
      default:
        break;
    }
    if (input[row].lastName
            && input[row].firstName
            && (location.state.selectedCourse.enrollment_type === 'username' || input[row].username)
            && !input[row].newRow) {
      const next = {
        id: tableInput.length + 1, lastName: null, firstName: null, username: null, newRow: false,
      };
      input.push(next);
      input[row].newRow = true;
      setTableInput([...input]);
    } else {
      setTableInput([...input]);
    }
  };

  const renderStudentsInput = () => tableInput.map((student, index) => (
    <tr key={index + 1} id={`userInput_${index + 1}`}>
      <td id="table-index">{index + 1}</td>
      <td>
        <FormInput
          title=""
          type="text"
          id={`lastName_${index + 1}`}
          placeholder="Enter Student's Last Name"
          value={student.lastName}
          onValueChange={onValueChange}
        />
      </td>
      <td>
        <FormInput
          title=""
          type="text"
          id={`firstName_${index + 1}`}
          placeholder="Enter Student's First Name"
          value={student.firstName}
          onValueChange={onValueChange}
        />
      </td>
      <td>
        <FormInput
          title=""
          type="text"
          id={`studentId_${index + 1}`}
          placeholder="Enter Student's Student ID"
          value={student.studentId}
          onValueChange={onValueChange}
        />
      </td>
      <td>
        {tableTitles[3].value !== 'username' ? (
          <>
            <FormInput
              title=""
              type="email"
              id={`email_${index + 1}`}
              placeholder={`Enter Student's ${tableTitles[3].inputStr}`}
              value={student.username}
              onValueChange={onValueChange}
            />
          </>
        ) : (
          <>
            <div id="username-text">
              <h7>Unique Username Will Be Generated</h7>
            </div>
          </>
        )}
      </td>
    </tr>
  ));

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const saveStudentInvitations = async () => {
    const course = location.state.selectedCourse;
    const login = location.state.selectedLogin;
    let errorCount = 0; let inputCount = 0; let
      invalidEmail = 0;
    if (data === undefined || data.length === 0) {
      tableInput.forEach((input) => {
        if (location.state.selectedCourse.enrollment_type === 'email' && input.username && !validateEmail(input.username)) invalidEmail++;
        if (tableInput.length === 1 && (!input.firstName && !input.lastName && (!input.username || location.state.selectedCourse.enrollment_type !== 'username'))) errorCount++;
        else if (input.firstName && input.lastName && (input.username || location.state.selectedCourse.enrollment_type === 'username')) {
          inputCount++;
        } else if (!input.firstName && !input.lastName && (!input.username || location.state.selectedCourse.enrollment_type !== 'username')) return;
        else if (!input.firstName || !input.lastName || (!input.username || location.state.selectedCourse.enrollment_type !== 'username')) errorCount++;
        else errorCount++;
      });
      if (inputCount === 0) setError('Invalid input. At least one row must be filled out completely');
      else if (errorCount > 0) setError('Invalid input. Rows must be filled out completely or left completely blank');
      else if (invalidEmail > 0) setError('Invalid input. Please enter a valid email address');
      else {
        history.push('/rostering/review', {
          course, login, tableInput, data,
        });
      }
    } else {
      data.forEach((input) => {
        if (location.state.selectedCourse.enrollment_type === 'email' && input[tableTitles[3].title] && !validateEmail(input[tableTitles[3].title])) invalidEmail++;
      });
      if (invalidEmail > 0) setError('Invalid input. Please enter a valid email address');
      else {
        history.push('/rostering/review', {
          course, login, tableInput, data,
        });
      }
    }
  };

  const renderRosterTable = () => data.map((student, index) => (
    <tr key={index + 1}>
      <td>{index + 1}</td>
      <td>{student['Last Name']}</td>
      <td>{student['First Name']}</td>
      <td>{student['Student ID']}</td>
      <td>
        {location.state.selectedCourse.enrollment_type !== 'username' ? (<>{student[tableTitles[3].title]}</>) : (<><h7>Unique Username Will Be Generated</h7></>)}
        <Button
          className="bg-transparent shadow-none border-0 delete-button"
          size="lg"
        >
          <FontAwesomeIcon onClick={() => { deleteFromTable(index); }} icon={faMinusCircle} color="red" />
        </Button>
      </td>
    </tr>
  ));

  const renderFileUpload = () => (<CSVUploader history={history} />);

  const deleteFromTable = (id) => {
    setData(data.slice(0, id).concat(data.slice(id + 1)));
    setStudents(data);
  };

  return (
    <MainLayout history={history}>
      <Row>
        <Col sm={12} lg={6}>
          <div className="d-flex mt-4">
            <h2>{location.state.selectedCourse.name}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>2. Add Students</h5>
        </Col>
      </Row>
      {error && (
        <Alert className="text-center mt-3 p-1" variant="danger">
          <small>{`${error}.`}</small>
        </Alert>
      )}
      <Table responsive className="mt-4">
        <thead>
          <tr
            className="text-white"
            style={{ backgroundColor: 'hsl(200, 92%, 51%)' }}
          >
            <th />
            {renderStudentTableHeader()}
          </tr>
        </thead>
        <tbody>
          {data && data.length ? (
            <>
              {renderRosterTable(data)}
            </>
          )
            : (
              <>
                {renderStudentsInput(tableInput)}
              </>
            )}
        </tbody>
      </Table>
      <Row>
        <Col>
          {renderFileUpload()}
        </Col>
      </Row>
      <Button
        variant="success"
        className="align-self-center submit button-style"
        onClick={saveStudentInvitations}
      >
        Save & Next
      </Button>
    </MainLayout>
  );
};

export default AddStudents;
