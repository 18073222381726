/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
import React, {
  createContext, useState, useEffect, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import apiWrapper from '../api';
import { authContext } from './auth-context';

export const webAppContext = createContext({});

const WebAppProvider = ({ children }) => {
  const { auth } = useContext(authContext);

  const [dataLoading, setDataLoading] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [requiredAssignments, setRequiredAssignments] = useState();
  const [lessonAssignments, setLessonAssignments] = useState([]);
  const [userActiveEnrollment, setUserActiveEnrollment] = useState();
  const lessonTotals = syllabus.reduce((acc, lesson) => ({
    ...acc,
    [lesson.code]: assignments.filter((ass) => ass.lesson_id === lesson.id).length,
  }), {});

  const activeUserSubmissions = useMemo(() => submissions.filter((sub) => sub.enrollment_id === userActiveEnrollment?.id), [submissions, userActiveEnrollment]);

  const lessonSolidTotals = (required) => syllabus.reduce((acc, lesson) => ({
    ...acc,
    [lesson.code]: assignments.filter(
      (ass) => {
        const code = ass.code.split('_')[0];
        const assignmentRequired = requiredAssignments?.length ? requiredAssignments?.find((req) => req === ass.id) : true;
        return code === lesson.code
          && (ass.kind === 'solid' || ass.kind === 'hidden')
          && (required ? assignmentRequired : !assignmentRequired);
      },
    ).length,
  }), {});

  const starsPerLesson = syllabus.reduce((acc, lesson) => ({
    ...acc,
    [lesson.code]: activeUserSubmissions.reduce((subAcc, sub) => {
      const lessonAssignment = assignments.find((item) => item.id === sub.lesson_assignment_id);
      if (lessonAssignment) {
        const code = lessonAssignment.code.split('_')[0];
        const assignmentRequired = requiredAssignments?.length ? requiredAssignments?.find((req) => req === lessonAssignment.id) : true;
        if (code !== lesson.code) {
          return subAcc;
        }

        if (!sub.is_correct) return subAcc;
        if (!assignmentRequired) {
          if (!sub.did_look_at_hint && !sub.did_peek && userActiveEnrollment.course.with_bonus) {
            return subAcc + 1;
          }
          return subAcc;
        }
        if (sub.did_peek) return subAcc + 1;
        if (sub.did_look_at_hint) return subAcc + 2;
        return subAcc + 3;
      }
      return subAcc;
    }, 0),
  }), {});

  const lessonAssignmentCompleted = (required) => syllabus.reduce((acc, lesson) => ({
    ...acc,
    [lesson.code]: activeUserSubmissions.reduce((subAcc, sub) => {
      const lessonAssignment = assignments.find((item) => item.id === sub.lesson_assignment_id);
      if (lessonAssignment) {
        const code = lessonAssignment.code.split('_')[0];
        const assignmentRequired = requiredAssignments?.length ? requiredAssignments?.find((req) => req === lessonAssignment.id) : true;
        if (sub.is_correct
          && code === lesson.code
          && (required ? assignmentRequired : !assignmentRequired)
        ) return subAcc + 1;
      }
      return subAcc;
    }, 0),
  }), {});

  const percentages = (required) => syllabus.reduce((acc, lesson) => {
    const solidTotals = lessonSolidTotals(required);
    const completedTotals = lessonAssignmentCompleted(required);
    return {
      ...acc,
      [lesson.code]: solidTotals[lesson.code] !== 0 ? Math.round((completedTotals[lesson.code] / solidTotals[lesson.code]) * 100) : 0,
    };
  }, {});

  const lessonGradingPercentages = syllabus.reduce((acc, lesson) => {
    const solidTotals = lessonSolidTotals(true);
    return {
      ...acc,
      [lesson.code]: Math.min(100, solidTotals[lesson.code] !== 0 ? Math.round((starsPerLesson[lesson.code] / (solidTotals[lesson.code] * 3)) * 100) : 0),
    };
  }, {});

  const stars = activeUserSubmissions?.reduce((acc, submission) => {
    if (!submission.is_correct) return acc;
    if (submission.did_peek) return acc + 1;
    if (submission.did_look_at_hint) return acc + 2;
    return acc + 3;
  }, 0) ?? 0;


  useEffect(() => {
    const setData = async () => {
      if (!auth?.user?.id) {
        return;
      }

      setDataLoading(true);
      const { data } = await apiWrapper.getSyllabus();
      const allAssignments = [];
      const syllabusLessons = data;
      syllabusLessons.sort((a, b) => a.display_order - b.display_order);
      let index = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const lesson of syllabusLessons) {
        const response = await apiWrapper.getAssignments(index + 1);
        allAssignments.push(...response.data.map((ass) => ({ ...ass, lesson_id: lesson.id })));
        index += 1;
      }
      const allLessonAssignments = await apiWrapper.getAllLessonAssignments();
      setLessonAssignments(allLessonAssignments.data);
      setSyllabus(syllabusLessons);
      setAssignments(allAssignments);
      const { data: loadedSubmissions } = await apiWrapper.getAllSubmissions();
      const { data: activeEnrollment } = await apiWrapper.getActiveEnrollment(auth?.user?.id);
      setUserActiveEnrollment(activeEnrollment);
      setSubmissions(loadedSubmissions);
      apiWrapper.getAllCourseAssignments(activeEnrollment?.course_id).then((requiredAss) => {
        setRequiredAssignments(requiredAss.data);
      }).catch(() => {
        setRequiredAssignments(undefined);
      });
      setDataLoading(false);
    };

    setData();
  }, [auth]);

  const updateSubmission = (submission) => {
    // Submission already exist: update
    const existingSubmission = submissions.find((sub) => sub.lesson_assignment_id === submission.lesson_assignment_id);
    if (existingSubmission) {
      setSubmissions(submissions.map((sub) => {
        if (sub.lesson_assignment_id === submission.lesson_assignment_id) {
          return submission;
        }
        return sub;
      }));
    } else {
      // New submission, push to the array
      setSubmissions([
        ...submissions,
        submission,
      ]);
    }
  };

  // When opening a lesson, we need to refresh the assignment sets and submissions
  const onLessonOpen = async () => {
    setDataLoading(true);
    try {
      const loadedSubmissions = await apiWrapper.getAllSubmissions();
      const courseAssignments = await apiWrapper.getAllCourseAssignments(userActiveEnrollment?.course_id);
      const activeEnrollment = await apiWrapper.getActiveEnrollment(auth?.user?.id);
      setUserActiveEnrollment(activeEnrollment.data);
      setSubmissions(loadedSubmissions?.data);
      setRequiredAssignments(courseAssignments.data);
    } catch (err) {
      setDataLoading(false);
      setRequiredAssignments(undefined);
    }

    setDataLoading(false);
  };

  return (
    <webAppContext.Provider value={{
      submissions: activeUserSubmissions,
      loading: submissions.loading,
      updateSubmission,
      stars,
      starsPerLesson,
      syllabus,
      lessonAssignments,
      percentages,
      lessonGradingPercentages,
      lessonTotals,
      assignments,
      enrollment: userActiveEnrollment,
      setUserActiveEnrollment,
      dataLoading,
      requiredAssignments,
      onLessonOpen,
      userActiveEnrollment,
    }}
    >
      {children}
    </webAppContext.Provider>
  );
};

WebAppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default WebAppProvider;
