import moment from 'moment';
import apiWrapper from '../api';
import groupBy from './group-by';

// Maps the column name to the lesson name
export const COLUMN_TO_NAME_MAP = {
  first_name: { label: 'First Name', course: false },
  last_name: { label: 'Last Name', course: false },
  email: { label: 'Email', course: false },
  user_id: { label: 'Internal Reference', course: false },
  student_id: { label: 'Student ID', course: false },
  intro: { label: 'Introduction', course: true },
  rot: { label: '2D Rotations', course: true },
  iso: { label: 'Iso Cubes', course: true },
  ortho: { label: 'Ortho Cubes', course: true },
  _2d3d: { label: '2D To 3D', course: true },
  slopes: { label: 'Slopes and Curves', course: true },
  flat: { label: 'Flat Patterns', course: true },
  rot1: { label: 'Rotations about 1 Axis', course: true },
  rot2: { label: 'Rotations about 2 Axis', course: true },
  assembly: { label: 'Assembly', course: true },
  stars: { label: 'Stars', course: false },
  username: { label: 'Username', course: false },
  persistenceAverage: { label: 'Average Lesson Grade', course: false },
  persistenceTotalStars: { label: 'Total Stars on Required and Not-Required Assignments', course: false },
};

const HEADER_FIELDS = [
  'last_name',
  'first_name',
  'email',
  'user_id',
];

const lessonsOrder = ['intro', 'rot', 'iso', 'ortho', '2d3d', 'slopes', 'flat', 'rot1', 'rot2', 'assembly'];

const getAllAssignments = async (newObject, grades, total) => {
  const arr = new Array(10).fill(0);
  for (let index = 0; index < arr.length; index++) {
    const response = await apiWrapper.getAssignments(index + 1);
    response.data.forEach(async (lesson) => {
      if (!isNaN(parseInt(lesson.code.split('_')[1]))) {
        const header = lesson.code.toLowerCase().indexOf('2d3d') >= 0 ? (`_${lesson.code.split('_')[0]}_${lesson.display_order}`).toLowerCase()
          : `${lesson.code.split('_')[0]}_${lesson.display_order}`;
        if (!newObject[header]) {
          const grade = grades.find((item) => item.email === newObject.email);
          if (grade && grade[header]) {
            newObject = {
              ...newObject,
              [header]: grade[header],
            };
            total += grade[header];
          } else {
            newObject = { ...newObject, [header]: '' };
          }
        }
      }
    });
  }
  return newObject;
};
// Remove all the keys not present in the assignment set
const starsForAssignmentSet = (assignmentSet, grades, isAllAssignments) => grades.map(async (grade) => {
  let newObject = {};
  let total = 0;

  if (isAllAssignments) {
    Object.keys(grade).forEach((key) => {
      if (HEADER_FIELDS.find((keyName) => keyName === key)) {
        newObject = {
          ...newObject,
          [key]: grade[key],
        };
        return;
      }
      newObject = {
        ...newObject,
        [key]: grade[key],
      };
      total += grade[key];
    });
    newObject = await getAllAssignments(newObject, grades, total);
  } else {
    Object.keys(grade).forEach((key) => {
      if (HEADER_FIELDS.find((keyName) => keyName === key)) {
        newObject = {
          ...newObject,
          [key]: grade[key],
        };
        return;
      }
      const inAssignmentSet = assignmentSet.assignments.find((assignment) => {
        const code = `${assignment.lesson_code}_${assignment.display_order}`;
        return code === key;
      });
      if (inAssignmentSet) {
        newObject = {
          ...newObject,
          [key]: grade[key],
        };
        total += grade[key];
      }
    });
    assignmentSet.assignments.forEach((assignment) => {
      const key = assignment.lesson_code.toLowerCase() === '2d3d' ? (`_${assignment.lesson_code}_${assignment.display_order}`).toLowerCase()
        : (`${assignment.lesson_code}_${assignment.display_order}`).toLowerCase();
      if (!newObject[key]) {
        const grade = grades.find((item) => item.email === newObject.email);
        if (grade[key]) {
          newObject = {
            ...newObject,
            [key]: grade[key],
          };
          total += grade[key];
        } else newObject = { ...newObject, [key]: '' };
      }
    });
    newObject.total = total;
  }
  return newObject;
});

// Returns the percent complete for each lesson for each student
// in the course for the given assignment set.
const percentCompleteForAssignmentSet = (lessons, assignmentSet, grades) => {
  const lessonAssignments = groupBy(assignmentSet.assignments, 'lesson_code');
  const orderedLessonAssignments = {};

  // set the lesson in assignmentset in correct order
  Object.keys(lessonAssignments).sort((a, b) => {
    const aDisplayOrder = lessons.find((lesson) => lesson.code === a).display_order;
    const bDisplayOrder = lessons.find((lesson) => lesson.code === b).display_order;
    return aDisplayOrder - bDisplayOrder;
  }).forEach((key) => {
    orderedLessonAssignments[key.toLowerCase()] = lessonAssignments[key];
  });

  // For every student, only grad the correct assignments that are in the assignmentset
  const formattedGrades = [];
  grades.forEach((grade) => {
    const newObject = {
      last_name: grade.last_name,
      first_name: grade.first_name,
      email: grade.email,
      user_id: grade.user_id,
    };

    let stars = 0;
    Object.keys(orderedLessonAssignments).forEach((lessonCode) => {
      let correctCount = 0;
      let count = 0;
      orderedLessonAssignments[lessonCode].forEach((assignment) => {
        const starsForAssignment = assignment.lesson_code.toLowerCase() !== '2d3d' ? grade[`${assignment.lesson_code}_${assignment.display_order}`] : grade[`_${assignment.lesson_code.toLowerCase()}_${assignment.display_order}`];
        if (starsForAssignment) {
          correctCount += 1;
          stars += starsForAssignment;
        }
        count += 1;
      });
      newObject[lessonCode] = (correctCount / count) * 100;
    });
    newObject.stars = stars;
    formattedGrades.push(newObject);
  });

  return formattedGrades;
};

// A function that exports the data to CSV
const toCSV = async (json, forStars) => {
  let csv = '';
  // CSV Header
  let keys = (json[0] && Object.keys(json[0])) || [];
  const orderedKeys = [];
  if (forStars) {
    const displayOrderKeyPairs = [];
    keys.forEach((key) => {
      const items = key.split('_');
      displayOrderKeyPairs.push({ key, displayOrder: parseInt(items[items.length - 1]) });
    });
    lessonsOrder.forEach((lesson) => {
      displayOrderKeyPairs.forEach((item) => {
        if (item.key.split('_')[item.key.split('_').length - 2] === lesson) orderedKeys.push(item.key);
      });
    });
    orderedKeys.unshift(...['last_name', 'first_name', 'email', 'user_id', 'student_id']);
    orderedKeys.push('total');
    keys = orderedKeys;
  }
  const keyHeaders = keys.map((key) => {
    if (COLUMN_TO_NAME_MAP[key]) {
      return COLUMN_TO_NAME_MAP[key].label;
    }
    return key;
  });
  csv += `${keyHeaders.join(',')}\n`;

  // CSV Data
  json.forEach((line) => {
    csv += `${keys.map((key) => line[key]).join(',')}\n`;
  });

  return csv;
};

// Downloads the CSV data. Hacky, but it is the easiest way.
const downloadCSV = (data, courseCode, type) => {
  const encodedUri = window.encodeURI(`data:text/csv;charset=utf-8,${data}`);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${type}_${courseCode}_${moment().format()}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
  link.remove();
};

export default class GradeDownloader {
  static async downloadStarsEarned(course, includeAssignmentSet, result = []) {
    let data;
    if (!includeAssignmentSet) {
      await toCSV(result, true).then((res) => data = res);
      downloadCSV(data, course.code, 'stars_earned_all_assignments');
      return;
    }
    await toCSV(result, true).then((res) => data = res);
    downloadCSV(data, course.code, 'stars_earned_assignment_set');
  }

  static async downloadPersistence(course, withBonus, result = []) {
    let data;
    const downloadName = withBonus ? 'Persistence-With-Bonus' : 'Persistence-Without-Bonus';
    await toCSV(result).then((res) => data = res);
    downloadCSV(data, course.name, downloadName);
  }

  static async downloadPercentComplete(course, includeAssignmentSet, result = []) {
    let data;
    if (!includeAssignmentSet) {
      await toCSV(result).then((res) => data = res);
      downloadCSV(data, course.code, 'percent_complete_all_assignments');
      return;
    }
    await toCSV(result).then((res) => data = res);
    downloadCSV(data, course.code, 'percent_complete_assignment_set');
  }

  static async downloadStarsEarnedPerLesson(course, includeAssignmentSet, result = []) {
    let data;
    if (!includeAssignmentSet) {
      await toCSV(result).then((res) => data = res);
      downloadCSV(data, course.code, 'stars_per_lesson_all_assignments');
      return;
    }
    await toCSV(result).then((res) => data = res);
    downloadCSV(data, course.code, 'stars_per_lesson_assignment_set');
  }
}
