import { axiosInstance } from '../utils/rest-client';

const apiWrapper = {
  onAuthentication: (email, password) => axiosInstance.post('/v1/auth/', { email, password }),
  onSignUp: (firstName, lastName, email, password) => axiosInstance.post('/v1/user/', {
    first_name: firstName, last_name: lastName, email, password,
  }),
  getCourses: () => axiosInstance.get('/v1/course/'),
  getCourse: (id) => axiosInstance.get(`/v1/course/${id}`),
  addCourse: (courseInformation) => axiosInstance.post('/v1/course/', courseInformation),
  editCourse: (courseInformation) => axiosInstance.put(`/v1/course/${courseInformation.id}`, courseInformation),
  getCourseTeachers: (courseId) => axiosInstance.get(`/v1/course/${courseId}`),
  getSchools: () => axiosInstance.get('/v1/school/'),
  addSchool: (fullAddress) => axiosInstance.post('/v1/school/', fullAddress),
  editSchool: (fullAddress) => axiosInstance.put(`/v1/school/${fullAddress.id}`, fullAddress),
  getUsers: (page) => axiosInstance.get(`/v1/user/?current_page=${page}`),
  searchUser: (searchRequest) => axiosInstance.get(`v1/search/user?query=${searchRequest}&size=100&current_page=1`),
  getTeachers: (search) => axiosInstance.get(`/v1/search/user?query=${search}&role=teacher`),
  addTeacher: (teacher) => axiosInstance.post('/v1/invite/', teacher),
  resetPassword: (id, key, password) => axiosInstance.put(`/v1/user/${id}/password`, { reset_key: key, new_password: password }),
  getStarGradings: (courseId) => axiosInstance.get(`/v1/gradings/${courseId}/stars`),
  getPercentGradings: (courseId) => axiosInstance.get(`/v1/gradings/${courseId}/percent_complete`),
  getPrice: (code) => axiosInstance.get(`/v1/search/course/${code}`),
  createIntent: (code, discount) => axiosInstance.post(`/v1/payment/intent/${code}`, { discount }),
  requestPasswordChange: (email) => axiosInstance.get(`/v1/user/${email}/forgotten`),
  enroll: (code) => axiosInstance.post(`/v1/enrollment/${code}`, null),
  enrollWithFreeCoupon: (code) => axiosInstance.post(`/v1/enrollment/${code}/coupon`, null),
  activateAccount: (id, key) => axiosInstance.get(`v1/user/${id}/activate/${key}`),
  getCourseStudents: (courseId, page, sortBy, order) => axiosInstance.get(`/v1/course/${courseId}/students?current_page=${page}&sort_by=${sortBy}&order_by=${order}`),
  searchStudent: (courseId, page, sortBy, order, searchRequest) => axiosInstance.get(`/v1/course/${courseId}/students?current_page=${page}&sort_by=${sortBy}&order_by=${order}&search=${searchRequest}`),
  getAllStudents: (page, sortBy, order) => axiosInstance.get(`/v1/search/student?current_page=${page}&sort_by=${sortBy}&order_by=${order}`),
  globalSearchStudent: (page, sortBy, order, searchRequest) => axiosInstance.get(`/v1/search/student?current_page=${page}&sort_by=${sortBy}&order_by=${order}&search=${searchRequest}`),
  getSyllabus: () => axiosInstance.get('/v1/syllabus/ENG/lessons'),
  createAssignmentSet: (name, assignmentIds) => axiosInstance.post('/v2/assignment_set/', { name, assignment_ids: assignmentIds }),
  editAssignmentSet: (assignmenSetId, name, assignmentIds) => axiosInstance.put(`/v2/assignment_set/${assignmenSetId}`, { name, assignment_ids: assignmentIds }),
  getAssignmentSets: () => axiosInstance.get('/v2/assignment_set/'),
  getAssignments: (id) => axiosInstance.get(`/v2/lesson/${id}/assignments`),
  updateCourseAssignmentSet: (courseId, assignmentSetId) => axiosInstance.put(`/v2/course/${courseId}/assignment_set`, { assignment_set_id: assignmentSetId }),
  getAllCourseAssignments: (courseId) => axiosInstance.get(`/v2/course/${courseId}/required_assignments`),
  createStudentInvitation: (username, courseId, firstName, lastName, studentId) => axiosInstance.post('/v2/invitation/', {
    username,
    course_id: courseId,
    first_name: firstName,
    last_name: lastName,
    student_id: studentId,
  }),
  getAllStudentInvitations: (id) => axiosInstance.get(`/v2/invitation/search/${id}`),
  getStudentInvitation: (username) => axiosInstance.get(`/v2/invitation/username/${username}`),
  acceptStudentInvitation: (username, password) => axiosInstance.post(`/v2/invitation/accept/${username}`, { password }),
  deactivateUser: (userId) => axiosInstance.get(`/v1/user/${userId}/deactivate`),
  resetPasswordByUsername: (userId, newPassword) => axiosInstance.put(`/v1/user/${userId}/student_password`, { new_password: newPassword }),
  updateCourseEnrollmentType: (courseId, enrollmentType) => axiosInstance.put(`/v2/course/${courseId}/enrollment_type`, { enrollment_type: enrollmentType }),
  changeAccountLevel: (userId, newAccountLevel) => axiosInstance.put(`/v1/user/${userId}/upgrade`, { new_account_level: newAccountLevel }),
  sendStudentInvitationEmail: (email) => axiosInstance.get(`/v2/invitation/accept/${email}`),
  enrollExistingUser: (username) => axiosInstance.post(`/v1/user/${username}/enroll`),
  searchInvitations: (courseId, searchRequest) => axiosInstance.get(`/v2/invitation/search/${courseId}/students?search=${searchRequest}`),
  getUserEnrollments: (userId) => axiosInstance.get(`/v1/user/${userId}/enrollments`),
  getAllSubmissions: () => axiosInstance.get('/v2/submission/'),
  submitAssignment: (submission) => axiosInstance.post('/v2/submission/', submission),
  getActiveEnrollment: (id) => axiosInstance.get(`/v1/user/${id}/active_enrollment`),
  getCoupon: (id) => axiosInstance.get(`v1/payment/coupon/${id}`),
  updateCoupon: (id, timesRedeemed) => axiosInstance.post(`v1/payment/coupon/update/${id}`, { timesRedeemed }),
  getAllCoupons: () => axiosInstance.get('v1/payment/coupons', { timeout: 600000 }),
  createCoupon: (couponInformation) => axiosInstance.post('v1/payment/coupon', couponInformation),
  getCurrentUser: () => axiosInstance.get('/v1/auth/current'),
  getAllSubmissionsForCourse: (courseId, userIds) => axiosInstance.get(`/v2/submission/${courseId}/course/${userIds}`),
  getEnrollment: (id) => axiosInstance.get(`v1/enrollment/${id}`),
  getAllUsersInCourse: (courseId) => axiosInstance.get(`v2/course/${courseId}/students`),
  getAllLessonAssignments: () => axiosInstance.get('v2/lesson/'),
};

export default apiWrapper;
