export let instructionModalToggled = false;

const setInstructionModalToggled = (value) => {
  instructionModalToggled = value;
};

export let selectedCourseIDTeacherInterface = 0;

const setSelectedCourseIDTeacherInterface = (value) => {
  selectedCourseIDTeacherInterface = value;
};

export let data = [];

const setData = (value) => {
  data = value;
};

export const loginOptions = {
  email: 'With their email addresses (recommended for students older than 13)',
  auto: 'With usernames automatically generated by eGrove Education (recommended for students younger than 13)',
  // google: 'With their Google Classroom Login',
  schoologyEmail: 'With their Schoology Email',
  schoologyUsername: 'With their Schoology Username',
  // canvas: 'With their Canvas Login',
  // azure: 'With their Azure Login'
};

export const tableTitles = [{
  title: 'Last name',
  value: 'last_name',
}, {
  title: 'First Name',
  value: 'first_name',
}, {
  title: 'Student ID',
  value: 'student_id',
}, {
  title: 'Email',
  value: 'email',
}];

export const reviewAndConfirmMessage = {
  email: 'By selecting “Confirm & Send Email Invites” the students listed above will be registered for the listed course. Students will receive invitations to set their passwords and access the application.',
  username: 'By selecting “Confirm” the students listed above will be registered for the listed course. Students will then be able to set their passwords and log in to the application with the given username.',
  sso_username: 'By selecting “Confirm” the students listed above will be registered for the listed course. Students will then be able to access the application using the provided single sign on username.',
  sso_email: 'By selecting “Confirm” the students listed above will be registered for the listed course. Students will then be able to access the application using the provided single sign on email address.',
};

const setTableTitles = (enrollment_type) => {
  tableTitles[3].title = setHeaderEnrollmentType(enrollment_type);
  tableTitles[3].value = enrollment_type;
  tableTitles[3].inputStr = (enrollment_type === 'email' || (enrollment_type && enrollment_type.split('_')[1] === 'email')) ? `${tableTitles[3].title} Address` : tableTitles[3].title;
};

export const NO_INVITATIONS_PROMPT = {
  question: '1. How will your students be logging in to the Spatial Vis app?',
  choices: [
    loginOptions.email,
    loginOptions.auto,
    // loginOptions.google,
    loginOptions.schoologyEmail,
    loginOptions.schoologyUsername,
    // loginOptions.canvas,
    // loginOptions.azure
  ],
};

const setHeaderEnrollmentType = (enrollment_type) => {
  switch (enrollment_type) {
    case 'email':
      return 'Email';
    case 'username':
      return 'Username';
    case 'sso_email':
      return 'Schoology Email';
    case 'sso_username':
      return 'Schoology Username';
    default:
      return 'Username';
  }
};

const setHeaderLoginMethod = (selection) => {
  switch (selection) {
    case loginOptions.email:
      setTableTitles('email');
      return 'email';
    case loginOptions.auto:
      setTableTitles('username');
      return 'username';
    case loginOptions.schoologyEmail:
      setTableTitles('sso_email');
      return 'sso_email';
    case loginOptions.schoologyUsername:
      setTableTitles('sso_username');
      return 'sso_username';
    default:
      break;
  }
};

export {
  setTableTitles, setHeaderLoginMethod, setInstructionModalToggled, setData, setSelectedCourseIDTeacherInterface,
};
